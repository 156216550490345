
.no-resize{
  resize: none;
}

.code{
  background: #f1f1f1;
  border: solid 1px #dddddd;
  border-radius: 4px;
  padding: 6px;
}